import * as React from 'react';

function IconMenu(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
      <path
        d="M3.5 7c0-.644.522-1.167 1.167-1.167h18.666a1.167 1.167 0 110 2.334H4.667A1.167 1.167 0 013.5 7zM3.5 14c0-.644.522-1.167 1.167-1.167h18.666a1.167 1.167 0 110 2.334H4.667A1.167 1.167 0 013.5 14zM4.667 19.833a1.167 1.167 0 000 2.334h18.666a1.167 1.167 0 100-2.334H4.667z"
        fill="#82FBFD"
      />
    </svg>
  );
}

export default IconMenu;
