import TokenLocker2 from 'contracts/abiTokenLocker2.json';

const list = [
  {
    key: 1,
    address: '0x55d5c682727211e7d0E52a3aaA6D0a6b70AAcF8F',
    title: 'Seed Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
  {
    key: 2,
    address: '0x9d059a36eE00067446f4B53044d12C075a2BED60',
    title: 'Private Sale 1 Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
  {
    key: 3,
    address: '0xaA28cA80caAB7d044158B26e1CA815712470709D',
    title: 'Private Sale 2 Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
  {
    key: 4,
    address: '0x883BC89DcFE88c7394C2770f536a7555f007712d',
    title: 'Strategic Partners Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
];

export default list;