import React, { FC } from 'react';

const BigIcon: FC<any> = ({ iconSrc, title = '', subtitle = '' }) => {
  return (
    <div className="big-icon">
      <div className="big-icon__container">
        <img src={iconSrc} alt={title} className="big-icon__image" />
        <div className="big-icon__content">
          <span className="big-icon__title">{title}</span>
          <span className="big-icon__subtitle">{subtitle}</span>
        </div>
      </div>
    </div>
  );
};

export default BigIcon;
