import IconConnectWallet from 'resources/images/icon-connect-wallet.png';
import IconClaim from 'resources/images/icon-claim.png';
import IconConfirmTransaction from 'resources/images/icon-confirm-transaction.png';
import GroupLine1 from 'resources/images/group-line-1.png';
import GroupLine2 from 'resources/images/group-line-2.png';
import GroupLine3 from 'resources/images/group-line-3.png';

const data: any = [
  {
    iconSrc: IconConnectWallet,
    title: 'Connect Wallet',
    subtitle: 'Connect to Metamask Wallet',
    lineSrc: GroupLine1,
    listContent: [
      'Please install Metamask wallet extension on your browser or Metamask Mobile App on your phone',
      'Make sure you already selected Ethereum Mainnet on Metamask wallet',
      'Make sure you already connected to your Contribution Wallet Address on Metamask',
    ],
  },
  {
    isReverseColumn: true,
    iconSrc: IconClaim,
    title: 'Claim your vesting',
    subtitle: 'Select vesting round and claim',
    lineSrc: GroupLine2,
    className: 'section-claim',
    listContent: [
      'Select the vesting round you would like to claim tokens',
      'Check your token information before claim',
      '"Available" is the remaining token balance after deducting the claimed tokens',
      '"Claimed" is the number of tokens you already claimed',
      '"Claimable" is the current number of tokens you allowed to claim',
    ],
  },
  {
    iconSrc: IconConfirmTransaction,
    title: 'Confirm Transaction',
    subtitle: 'Confirm to claim on Metamask',
    lineSrc: GroupLine3,
    className: 'section-confirm-transaction',
    listContent: [
      'Confirm paying gas fee on Metamask to proceed the claimed transaction',
      'After confirmation, the transaction hash will be displayed on the screen',
      'Click on transaction hash to view detail transaction on Etherscan',
      'Recheck your wallet and contact us if you have not received your tokens',
    ],
  },
];

export default data;
