import { Col, Layout, Row } from 'antd';
import BigIcon from 'components/BigIcon';
import React, { Fragment } from 'react';

import data from './data';

const HowItWorks = () => {
  return (
    <Layout className="how-it-works">
      <div className="container">
        <h4 className="title">How it works</h4>

        {data.map((item: any, index: number) => (
          <Fragment key={index}>
            {!item.isReverseColumn && (
              <Row gutter={[24, 20]}>
                <Col xs={24} lg={6}>
                  <BigIcon iconSrc={item.iconSrc} title={item.title} subtitle={item.subtitle} />
                </Col>
                <Col lg={6} xs={0}>
                  <img src={item.lineSrc} alt={item.title} />
                </Col>
                <Col lg={12}>
                  {item.listContent.map((item: any, index: number) => (
                    <div key={index} className="box-item">
                      {item}
                    </div>
                  ))}
                </Col>
              </Row>
            )}
            {item.isReverseColumn && (
              <Row gutter={[24, 20]} className={item.isReverseColumn && 'row-reverse'}>
                <Col xs={24} lg={0}>
                  <BigIcon iconSrc={item.iconSrc} title={item.title} subtitle={item.subtitle} />
                </Col>
                <Col lg={12}>
                  {item.listContent.map((item: any, index: number) => (
                    <div key={index} className="box-item">
                      {item}
                    </div>
                  ))}
                </Col>
                <Col lg={6} xs={0}>
                  <img src={item.lineSrc} alt={item.title} />
                </Col>
                <Col xs={0} lg={6}>
                  <BigIcon iconSrc={item.iconSrc} title={item.title} subtitle={item.subtitle} />
                </Col>
              </Row>
            )}
          </Fragment>
        ))}
      </div>
    </Layout>
  );
};

export default HowItWorks;
