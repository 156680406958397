import React, { memo, useRef } from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router';

import HeaderCommon from '../../components/Header';
import FooterComponent from '../../components/Footer';
import HomePage from 'pages/home';
import HowItWorks from 'pages/howItWorks';
import { socialLinks } from 'pages/layout/socials';

const { Content } = Layout;

function LayoutCommon() {
  const connectButtonRef = useRef(null) as any;

  return (
    <Layout className="layout">
      <Layout>
        <HeaderCommon connectButtonRef={connectButtonRef} />
        <Content>
          <ul className="social">
            {socialLinks.map(({ name, icon, link }: any) => (
              <li className="social__icon" key={name}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <img src={icon} alt={name} />
                </a>
              </li>
            ))}
          </ul>
          <Switch>
            <Route path="/" exact render={(props) => <HomePage {...props} connectButtonRef={connectButtonRef} />} />
            <Route path="/how-it-works" exact render={(props) => <HowItWorks />} />
          </Switch>
        </Content>
        <FooterComponent />
      </Layout>
    </Layout>
  );
}
export default memo(LayoutCommon);
