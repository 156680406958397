import * as React from 'react';

function IconClose(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
      <path
        d="M8.225 8.225a1.167 1.167 0 000 1.65L12.35 14l-4.125 4.125a1.167 1.167 0 101.65 1.65L14 15.65l4.125 4.125a1.167 1.167 0 001.65-1.65L15.65 14l4.125-4.125a1.167 1.167 0 00-1.65-1.65L14 12.35 9.875 8.225a1.167 1.167 0 00-1.65 0z"
        fill="#82FBFD"
      />
    </svg>
  );
}

export default IconClose;
