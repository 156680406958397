import IconTwitter from 'resources/images/icon-twitter.png';
import IconTelegram from 'resources/images/icon-telegram.png';
import IconReddit from 'resources/images/icon-reddit.png';
import IconMedium from 'resources/images/icon-medium.png';
import IconDiscord from 'resources/images/icon-discord.png';

const socialLinks = [
  {
    name: 'Twitter',
    icon: IconTwitter,
    link: 'https://twitter.com/ShoeFyio',
  },
  {
    name: 'Telegram',
    icon: IconTelegram,
    link: 'https://t.me/ShoeFy_Official',
  },
  {
    name: 'Reddit',
    icon: IconReddit,
    link: 'https://www.reddit.com/r/ShoeFy',
  },
  {
    name: 'Medium',
    icon: IconMedium,
    link: 'https://medium.com/@ShoeFy',
  },
  {
    name: 'Discord',
    icon: IconDiscord,
    link: 'https://discord.com/invite/vWrsyaw7hB',
  },
];

export { socialLinks };
